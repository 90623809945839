<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 47 20"
    class="logo"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.69535 20V0H0V20H3.69535Z" fill="currentColor" />
    <path
      d="M28.4591 20V0H23.8047L18.5296 14.2454L13.2828 0H8.37447V20H11.9006V5.55712L17.0628 19.7179H19.7426L24.933 5.72638V20H28.4591Z"
      fill="currentColor"
    />
    <path
      d="M46.935 19.9806V17.6299H31.7305V19.9806H46.935Z"
      fill="currentColor"
    />
  </svg>
</template>
